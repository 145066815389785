import React from "react";
import Layout from "../component/layout";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import Icon from "../images/card-icon.png";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import FooterComponent from "../component/footerComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import AccordionComponent from "../component/accordionComponent";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
// import HomeImage from "../images/testing-quality-home-image.png";
import CardsComponent from "../component/CardsComponent";
import { graphql } from "gatsby";
import BorderCards from "../component/BorderCards";

const ServiceTestingQuality = (props) => {
  const seo = {
    title: "Quality Assurance and Software Testing Services | MotomTech",
    metaDesc:
      "Leave nothing to chance with our end-to-end custom software testing solutions. Reduce cost and rest easy with our all-encompassing QA services.",
  };

  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homeImage },
            },
          },
        },
        aboutUsHomeSection: {
          aboutUsHomeTitle,
          aboutUsHomeDescription,
          aboutUsHomeSolutionTitle,
          aboutUsHomeSolutionTextOne,
          aboutUsHomeSolutionTextTwo,
          aboutUsHomeSolutionTextThree,
        },
        servicePageTeamSection: {
          servicePageTeamTitle,
          servicePageTeamDescription,
          servicePageTeamProduct,
          servicePageTeamBusinessAnalystTitle,
          servicePageTeamBusinessAnalystDescription,
          servicePageTeamProductTitle,
          servicePageTeamProductDescription,
          servicePageTeamDesignTitle,
          servicePageTeamDesignDescription,
          servicePageTeamWriterTitle,
          servicePageTeamWriterDescription,
          servicePageTeamImplementation,
          servicePageTeamManageTitle,
          servicePageTeamManagerDescription,
          servicePageTeamFrontendTitle,
          servicePageTeamFrontendDescription,
          servicePageTeamBackendTitle,
          servicePageTeamBackendDescription,
          servicePageTeamDevopsTitle,
          servicePageTeamDevopsDescription,
          servicePageTeamQaTitle,
          servicePageTeamQaDescription,
          serviceSoftwareTesting,
        },
        customDevelopmentCards: {
          customDevelopmentTitle,
          customDevelopmentDescription,
          customDevelopmentCardTitle1,
          customDevelopmentCardDescription1,
          customDevelopmentCardTitle2,
          customDevelopmentCardDescription2,
          customDevelopmentCardTitle3,
          customDevelopmentCardDescription3,
          customDevelopmentCardTitle4,
          customDevelopmentCardDescription4,
          customDevelopmentCardTitle5,
          customDevelopmentCardDescription5,
          customDevelopmentCardTitle6,
          customDevelopmentCardDescription6,
          customDevelopmentCardTitle7,
          customDevelopmentCardDescription7,
          customDevelopmentCardTitle8,
          customDevelopmentCardDescription8,
          customDevelopmentCardTitle9,
        },
        digitalApplicationDevelopmentServices: {
          digitalApplicationDevelopmentFrontend,
          digitalApplicationDevelopmentBackend,
          digitalApplicationDevelopmentProgressive,
          digitalApplicationDevelopmentSaas,
          digitalApplicationDevelopmentECommerce,
          digitalApplicationDevelopmentCustom,
          digitalApplicationDevelopmentTitle,
        },
        processSection: {
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
          processAgileTitle,
          processAgileDescription,
        },
      },
    },
  } = props;

  const cards = [
    {
      icon: Icon,
      title: customDevelopmentCardTitle1,
      description: customDevelopmentCardDescription1,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle2,
      description: customDevelopmentCardDescription2,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle3,
      description: customDevelopmentCardDescription3,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle4,
      description: customDevelopmentCardDescription4,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle5,
      description: customDevelopmentCardDescription5,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle6,
      description: customDevelopmentCardDescription6,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle7,

      description: customDevelopmentCardDescription7,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle8,
      description: customDevelopmentCardDescription8,
    },
  ];
  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAnalysisDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processProductDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 2,
    },
  ];
  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processDesigningDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 3,
    },
    {
      title: processAgileTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: processAgileDescription.replace(/<br\s?\/?>/g, ""),
          }}
        />
      ),
      eventKey: 4,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={homeImage}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={aboutUsHomeTitle}
        description1={aboutUsHomeDescription}
        description2={aboutUsHomeSolutionTitle}
        description3={aboutUsHomeSolutionTextOne}
        description4={aboutUsHomeSolutionTextTwo}
        description5={aboutUsHomeSolutionTextThree}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <p
          className="section-container-title"
          dangerouslySetInnerHTML={{ __html: servicePageTeamTitle }}
        />
        <BorderCards
          borderCardsText1={servicePageTeamDescription}
          borderCardsText2={servicePageTeamProduct}
          borderCardsText3={servicePageTeamBusinessAnalystTitle}
          borderCardsText4={servicePageTeamBusinessAnalystDescription}
          borderCardsText5={servicePageTeamProductTitle}
          borderCardsText6={servicePageTeamProductDescription}
          borderCardsText7={servicePageTeamDesignTitle}
          borderCardsText8={servicePageTeamDesignDescription}
          borderCardsText9={servicePageTeamWriterTitle}
          borderCardsText10={servicePageTeamWriterDescription}
          borderCardsText11={servicePageTeamImplementation}
          borderCardsText12={servicePageTeamManageTitle}
          borderCardsText13={servicePageTeamManagerDescription}
          borderCardsText14={servicePageTeamFrontendTitle}
          borderCardsText15={servicePageTeamFrontendDescription}
          borderCardsText16={servicePageTeamBackendTitle}
          borderCardsText17={servicePageTeamBackendDescription}
          borderCardsText18={servicePageTeamDevopsTitle}
          borderCardsText19={servicePageTeamDevopsDescription}
          borderCardsText20={servicePageTeamQaTitle}
          borderCardsText21={servicePageTeamQaDescription}
          borderCardsText22={serviceSoftwareTesting}
        />
      </div>

      <CardsComponent
        title={customDevelopmentTitle}
        description={customDevelopmentDescription}
        description2={customDevelopmentCardTitle9}
        cards={cards}
        showCards={true}
      />
      <ItExpandTeamComponent
        title={digitalApplicationDevelopmentFrontend}
        description1={digitalApplicationDevelopmentBackend}
        description2={digitalApplicationDevelopmentProgressive}
        description3={digitalApplicationDevelopmentSaas}
        description4={digitalApplicationDevelopmentECommerce}
        description5={digitalApplicationDevelopmentCustom}
        description6={digitalApplicationDevelopmentTitle}
      />
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={"Frequently asked questions"}
        subtitle={"Here you can find some questions that our user ask te most."}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={
            "Software Quality is done better with Motomtech"
          }
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceTestingQuality;

export const query = graphql`
  {
    wpPage(slug: { eq: "software-testing-and-quality" }) {
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      aboutUsHomeSection {
        aboutUsHomeTitle
        aboutUsHomeDescription
        aboutUsHomeSolutionTitle
        aboutUsHomeSolutionTextOne
        aboutUsHomeSolutionTextTwo
        aboutUsHomeSolutionTextThree
      }
      servicePageTeamSection {
        servicePageTeamTitle
        servicePageTeamDescription
        servicePageTeamProduct
        servicePageTeamBusinessAnalystTitle
        servicePageTeamBusinessAnalystDescription
        servicePageTeamProductTitle
        servicePageTeamProductDescription
        servicePageTeamDesignTitle
        servicePageTeamDesignDescription
        servicePageTeamWriterTitle
        servicePageTeamWriterDescription
        servicePageTeamImplementation
        servicePageTeamManageTitle
        servicePageTeamManagerDescription
        servicePageTeamFrontendTitle
        servicePageTeamFrontendDescription
        servicePageTeamBackendTitle
        servicePageTeamBackendDescription
        servicePageTeamDevopsTitle
        servicePageTeamDevopsDescription
        servicePageTeamQaTitle
        servicePageTeamQaDescription
        serviceSoftwareTesting
      }
      customDevelopmentCards {
        customDevelopmentTitle
        customDevelopmentDescription
        customDevelopmentCardTitle1
        customDevelopmentCardDescription1
        customDevelopmentCardTitle2
        customDevelopmentCardDescription2
        customDevelopmentCardTitle3
        customDevelopmentCardDescription3
        customDevelopmentCardTitle4
        customDevelopmentCardDescription4
        customDevelopmentCardTitle5
        customDevelopmentCardDescription5
        customDevelopmentCardTitle6
        customDevelopmentCardDescription6
        customDevelopmentCardTitle7
        customDevelopmentCardDescription7
        customDevelopmentCardTitle8
        customDevelopmentCardDescription8
        customDevelopmentCardTitle9
      }
      digitalApplicationDevelopmentServices {
        digitalApplicationDevelopmentFrontend
        digitalApplicationDevelopmentBackend
        digitalApplicationDevelopmentProgressive
        digitalApplicationDevelopmentSaas
        digitalApplicationDevelopmentECommerce
        digitalApplicationDevelopmentCustom
        digitalApplicationDevelopmentTitle
      }
      processSection {
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
        processAgileTitle
        processAgileDescription
      }
    }
  }
`;
